import React from "react";
import loadable from "@loadable/component";

const AccordionList = loadable(() => import("@organisms/AccordionList"));
const BlogPreview = loadable(() => import("@organisms/BlogPreview"));
const CardCarousel = loadable(() => import("@organisms/CardCarousel"));
const ColumnCopy = loadable(() => import("@organisms/ColumnCopy"));
const Copy = loadable(() => import("@organisms/Copy"));
const CopyGrid = loadable(() => import("@organisms/CopyGrid"));
const Cta = loadable(() => import("@organisms/Cta"));
const CtaGrid = loadable(() => import("@organisms/CtaGrid"));
const DonationForm = loadable(() => import("@organisms/DonationForm"));
const Directory = loadable(() => import("@organisms/Directory"));
const EventCarousel = loadable(() => import("@organisms/EventCarousel"));
const Hero = loadable(() => import("@organisms/Hero"));
const ImageBlock = loadable(() => import("@organisms/ImageBlock"));
const ImageCopy = loadable(() => import("@organisms/ImageCopy"));
const InfoGrid = loadable(() => import("@organisms/InfoGrid"));
const JobList = loadable(() => import("@organisms/JobList"));
const LocationGrid = loadable(() => import("@organisms/LocationGrid"));
const LogoGrid = loadable(() => import("@organisms/LogoGrid"));
const PeopleGrid = loadable(() => import("@organisms/PeopleGrid"));
const Schedule = loadable(() => import("@organisms/Schedule"));
const SectionHeading = loadable(() => import("@organisms/SectionHeading"));
const Testimonial = loadable(() => import("@organisms/Testimonial"));
const OpenStudiosMap = loadable(() => import("@organisms/OpenStudiosMap"));

const PageBuilder = ({ blocks }) => {
  return (
    <>
      {blocks.map((b, i) => {
        const { uid, type } = b;
        switch (type) {
          case "accordionList":
            return <AccordionList key={uid} {...b} />;
          case "blogPreview":
            return <BlogPreview key={uid} {...b} />;
          case "cardCarousel":
            return <CardCarousel key={uid} {...b} />;
          case "columnCopy":
            return <ColumnCopy key={uid} {...b} />;
          case "copy":
            return <Copy key={uid} {...b} />;
          case "copyGrid":
            return <CopyGrid key={uid} {...b} />;
          case "cta":
            return <Cta key={uid} {...b} />;
          case "ctaGrid":
            return <CtaGrid key={uid} {...b} />;
          case "donationForm":
            return <DonationForm key={uid} {...b} />;
          case "directory":
            return <Directory key={uid} {...b} />;
          case "eventCarousel":
            return <EventCarousel key={uid} {...b} />;
          case "image":
            return <ImageBlock key={uid} {...b} />;
          case "imageCopy":
            return <ImageCopy key={uid} {...b} />;
          case "infoGrid":
            return <InfoGrid key={uid} {...b} />;
          case "jobList":
            return <JobList key={uid} {...b} />;
          case "locationGrid":
            return <LocationGrid key={uid} {...b} />;
          case "logoGrid":
            return <LogoGrid key={uid} {...b} />;
          case "openStudiosMap":
            return <OpenStudiosMap key={uid} {...b} />;
          case "peopleGrid":
            return <PeopleGrid key={uid} {...b} />;
          case "hero":
            return <Hero key={uid} {...b} />;
          case "schedule":
            return <Schedule key={uid} {...b} />;
          case "sectionHeading":
            return <SectionHeading key={uid} {...b} />;
          case "testimonial":
            return <Testimonial key={uid} {...b} />;
          case "utilityAnchorLink":
            return <div className="anchorUtility" key={uid} id={b.anchor} />;
          default:
            return (
              <div
                key={uid}
                className="flex w-full items-center justify-center p-10"
              >
                {b.type}
              </div>
            );
        }
      })}
    </>
  );
};

PageBuilder.defaultProps = {
  hasHero: true,
};

export default PageBuilder;
